import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import type {
  Availability,
  AvailabilityNote,
} from "practicare/types/availability.model";
import type { PublicAvailability } from "practicare/types/publicAvailability.model";
import { mapAvailability } from "src/mappers/availability";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface AvailabilityState {
  notesRaw: AvailabilityNote[];
  publicAvailability: PublicAvailability[];
  notes: any[];
  data: Availability[];
  userAvailability: Availability[];
  allUserAvailability: Availability[];
  updatedAt: string;
}

const initialState: AvailabilityState = {
  notesRaw: [],
  publicAvailability: [],
  notes: [],
  data: [],
  userAvailability: [],
  allUserAvailability: [],
  updatedAt: Date.now().toString(),
};

const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    setAvailabilityData(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setAvailabilityNotesRaw(
      state,
      action: PayloadAction<{ notesRaw: any[]; notes: any[] }>
    ) {
      state.notesRaw = action.payload.notesRaw;
      state.notes = action.payload.notes;
    },
    setUserAvailabilityData(state, action: PayloadAction<any[]>) {
      state.userAvailability = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setAllUserAvailabilityData(state, action: PayloadAction<any[]>) {
      state.allUserAvailability = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setPublicAvailability(state, action: PayloadAction<PublicAvailability[]>) {
      state.publicAvailability = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

const {
  setAvailabilityData,
  setAvailabilityNotesRaw,
  setUserAvailabilityData,
  setPublicAvailability,
  setAllUserAvailabilityData,
} = availabilitySlice.actions;

export const subscribeToAvailabilityData = (
  start: Date,
  locationId: string | null
) => {
  const constraints = [where("isDeleted", "==", false)];
  if (locationId) constraints.push(where("location.id", "==", locationId));

  const unsubscribe = onSnapshot(
    query(collection(db, "availability"), ...constraints),
    (snapshot) => {
      const availabilityData = snapshot.docs.map((doc) => mapAvailability(doc));
      const startMoment = moment(start).subtract(10, "minutes");
      const filteredAvailability = availabilityData.filter((a) =>
        a && !a.isDeleted && start
          ? moment(a.endDate).isSameOrAfter(startMoment)
          : true
      );
      store.dispatch(setAvailabilityData(filteredAvailability));
    }
  );

  return unsubscribe;
};

export const subscribeToUserAvailabilityData = (
  start: Date | null,
  end: Date | null,
  userId: string
) => {
  const constraints = [
    where("isDeleted", "==", false),
    where("theraphist.id", "==", userId),
  ];

  const unsubscribe = onSnapshot(
    query(collection(db, "availability"), ...constraints),
    (snapshot) => {
      const userAvailabilityData = snapshot.docs.map((doc) => ({
        ...(doc.data() as Availability),
        id: doc.id,
        startDate: moment(doc.data().startDate?.toDate()).toDate(),
        endDate: moment(doc.data().endDate?.toDate()).toDate(),
        startHour: Array.isArray(doc.data().startHour)
          ? moment(doc.data().startHour[0].toDate()).toDate()
          : doc.data().startHour
            ? moment(doc.data().startHour.toDate()).toDate()
            : null,
        endHour: Array.isArray(doc.data().endHour)
          ? moment(doc.data().endHour[0].toDate()).toDate()
          : doc.data().endHour
            ? moment(doc.data().endHour.toDate()).toDate()
            : null,
        type: doc.data().type || {
          value: "WORKING_HOURS",
          label: "Godziny pracy",
        },
      }));
      const filteredAvailability = userAvailabilityData.filter(
        (a) =>
          !a.isDeleted &&
          (a.type?.value !== "WORKING_HOURS" ||
            ((start ? moment(a.endDate).isSameOrAfter(start) : true) &&
              (end ? moment(a.startDate).isSameOrBefore(end) : true)))
      );
      store.dispatch(
        setUserAvailabilityData(sortBy(filteredAvailability, "dayOfWeek.value"))
      );
    }
  );

  return unsubscribe;
};

export const subscribeToAllUserAvailabilityData = (userId: string) => {
  const constraints = [
    where("isDeleted", "==", false),
    where("theraphist.id", "==", userId),
  ];

  const unsubscribe = onSnapshot(
    query(collection(db, "availability"), ...constraints),
    (snapshot) => {
      const userAvailabilityData = snapshot.docs.map((doc) => ({
        ...(doc.data() as Availability),
        id: doc.id,
        startDate: moment(doc.data().startDate?.toDate()).toDate(),
        endDate: moment(doc.data().endDate?.toDate()).toDate(),
        startHour: Array.isArray(doc.data().startHour)
          ? moment(doc.data().startHour[0].toDate()).toDate()
          : doc.data().startHour
            ? moment(doc.data().startHour.toDate()).toDate()
            : null,
        endHour: Array.isArray(doc.data().endHour)
          ? moment(doc.data().endHour[0].toDate()).toDate()
          : doc.data().endHour
            ? moment(doc.data().endHour.toDate()).toDate()
            : null,
        type: doc.data().type || {
          value: "WORKING_HOURS",
          label: "Godziny pracy",
        },
      }));
      const filteredAvailability = userAvailabilityData.filter(
        (a) => !a.isDeleted && a.type?.value === "WORKING_HOURS"
      );
      store.dispatch(
        setAllUserAvailabilityData(
          sortBy(filteredAvailability, "dayOfWeek.value")
        )
      );
    }
  );

  return unsubscribe;
};

export const subscribeToAvailabilityNotes = () => {
  const constraints = [where("isDeleted", "==", false)];
  const unsubscribe = onSnapshot(
    query(collection(db, "availabilityNotes"), ...constraints),
    (snapshot) => {
      const notesRaw = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const notes = snapshot.docs.map((doc) => {
        const data = doc.data() as AvailabilityNote;
        return {
          id: doc.id,
          resourceId: data.userId,
          title: data.note,
          type: "NOTE",
          backgroundColor: "antiquewhite",
          borderColor: "lightgrey",
          fontSize: 5,
          allDay: true,
          start: data.singleDay
            ? moment((data.displayDate as any).toDate())
                .startOf("day")
                .toDate()
            : false,
          end: data.singleDay
            ? moment((data.displayDate as any).toDate())
                .endOf("day")
                .toDate()
            : false,
          startRecur: !data.singleDay,
          endRecur: !data.singleDay,
          daysOfWeek: data.singleDay
            ? false
            : Object.keys(data as any).reduce((acc: number[], key: any) => {
                if ((data as any)[key] === true && key.includes("dayOfWeek")) {
                  acc.push(Number(key.replace("dayOfWeek", "")) % 7);
                }
                return acc;
              }, []),
        };
      });
      store.dispatch(setAvailabilityNotesRaw({ notesRaw, notes }));
    }
  );

  return unsubscribe;
};

const publicAvailabilitySub: any = {
  unsub: null,
};
export const subscribeToPublicAvailability = () => {
  if (publicAvailabilitySub.unsub) {
    publicAvailabilitySub.unsub();
  }
  publicAvailabilitySub.unsub = onSnapshot(
    query(collection(db, "publicAvailability")),
    (snapshot) => {
      const availability: PublicAvailability[] = snapshot.docs.map((doc) => ({
        ...(doc.data() as PublicAvailability),
        id: doc.id,
      }));
      store.dispatch(setPublicAvailability(availability));
    }
  );
};

export default availabilitySlice.reducer;
