import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { UserAvailableSlot } from "practicare/types/userAvailableSlots.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let userAvailableSlotsSub: any = null;

export interface UserAvailableSlotsState {
  data: UserAvailableSlot[];
  updatedAt: string;
  loading: boolean;
}

const initialState: UserAvailableSlotsState = {
  data: [] as UserAvailableSlot[],
  updatedAt: Date.now().toString(),
  loading: true,
};

export const userAvailableSlotSlice = createSlice({
  name: "userAvailableSlots",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
      state.loading = false;
    },
  },
});

export const subscribeToUserAvailableSlots = (userId?: string) => {
  if (userAvailableSlotsSub) {
    return;
  }
  try {
    const constraints = [where("isDeleted", "==", false)];
    if (userId) constraints.push(where("user.id", "==", userId));
    userAvailableSlotsSub = onSnapshot(
      query(collection(db, "userAvailableSlots"), ...constraints),
      (data) => {
        const userAvailableSlots: any = [];
        data.forEach((doc) => {
          userAvailableSlots.push({
            id: doc.id,
            ...doc.data(),
            dDateTime: doc.data()?.createdAt?.toDate(),
          });
        });
        const ordered = orderBy(userAvailableSlots, "dDateTime", "asc");
        store.dispatch(userAvailableSlotSlice.actions.setData(ordered));
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default userAvailableSlotSlice.reducer;
