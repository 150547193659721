import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { User } from "practicare/types/user.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface UsersState {
  data: User[];
  notArchivedUsers: User[];
  singleUser: User | null;
}

// Define initial state
const initialState: UsersState = {
  data: [],
  notArchivedUsers: [],
  singleUser: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<User[]>) => {
      state.data = action.payload;
      state.notArchivedUsers = action.payload.filter(
        (user) => user.status !== "ARCHIVED"
      );
    },
    setSingleUser: (state, action: PayloadAction<User | null>) => {
      state.singleUser = action.payload;
    },
  },
});

// ** User Subscription Function
let subscriptionUsers: (() => void) | null = null;

const subscriptionSingleUser = {
  sub: null as (() => void) | null,
  id: "",
};
export const subscribeToUsersCollection = () => {
  if (subscriptionUsers) {
    return;
  }
  try {
    subscriptionUsers = onSnapshot(
      query(collection(db, "users"), where("isDeleted", "!=", true)),
      (data) => {
        const users: User[] = [];
        data.forEach((doc) => {
          users.push({
            ...(doc.data() as User),
            id: doc.id,
          });
        });
        const orderedUsers = orderBy(users, ["lastName", "firstName"], ["asc"]);
        store.dispatch(userSlice.actions.setData(orderedUsers));
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export const subscribeToUser = (id: string) => {
  if (subscriptionSingleUser.sub) {
    if (subscriptionSingleUser.id !== id) {
      subscriptionSingleUser.sub();
    } else {
      return;
    }
  }
  subscriptionSingleUser.id = id;
  store.dispatch(userSlice.actions.setSingleUser(null));
  try {
    subscriptionSingleUser.sub = onSnapshot(doc(db, "users", id), (data) => {
      const userData = data.exists()
        ? {
            ...(data.data() as User),
            id: data.id,
          }
        : null;
      store.dispatch(userSlice.actions.setSingleUser(userData));
    });
  } catch (e: any) {
    console.error(e);
  }
};
// ** Exporting the reducer
export default userSlice.reducer;
