import classNames from "classnames";
import { Controller } from "react-hook-form";
import { FormFeedback, Input, Label } from "reactstrap";
import styled from "styled-components";

const StyledInput = styled(Input)`
  position: relative;
`;

export const renderCheckboxField = (
  name: string,
  label: string,
  control: any,
  errors: any,
  defaultChecked?: boolean,
  styles?: any,
  onChangeFnc?: any
) => (
  <div
    className={classNames({
      ["mb-1"]: !styles?.hideMargin,
      ["w-50"]: styles?.width50,
    })}
    style={{ display: "flex", alignItems: "center", position: "relative" }}
  >
    <Label
      className="form-label"
      for={name}
      style={{
        width: "90%",
        marginBottom: "0",
        cursor: "pointer",
      }}
    >
      {label}
    </Label>
    <Controller
      control={control}
      name={name}
      render={({ field }: { field: any }) => (
        <StyledInput
          type="checkbox"
          id={name}
          {...field}
          checked={field.value}
          onChange={(e: any) => {
            if (onChangeFnc) {
              onChangeFnc(e);
            }
            field.onChange(e);
          }}
          style={{
            marginLeft: "auto",
          }}
        />
      )}
    />
    {errors[name] && (
      <FormFeedback style={{ width: "100%", marginLeft: "0" }}>
        {errors[name]?.message}
      </FormFeedback>
    )}
  </div>
);
