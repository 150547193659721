import React, { createContext, useContext, useState } from "react";
import { AppointmentAddModalAdminCustomizable } from "src/components/modals/appointmentAddModalAdminCustomizable";
import { AppointmentAddModalCustomizable } from "src/components/modals/appointmentAddModalCustomizable";
import { useAuth } from "src/context/auth";

// Define the correct type for setIsModalOpen

export interface AppointmentAddModalData {
  defaultLocationId?: string;
  defaultDate?: Date;
  defaultUserId?: string;
  suggestLocationAndRoom: boolean;
}
interface AppointmentAddModalContextType {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setModalData: (data?: AppointmentAddModalData | null | undefined) => void;
}

// Create the context with the correct type or undefined
const AppointmentAddModalContext = createContext<
  AppointmentAddModalContextType | undefined
>(undefined);

// Provider component
const AppointmentAddModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAdmin } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<
    AppointmentAddModalData | undefined | null
  >(null);

  const setModalData = (data: AppointmentAddModalData | undefined | null) => {
    setOptions(
      data
        ? data
        : {
            defaultLocationId: undefined,
            defaultDate: undefined,
            defaultUserId: undefined,
            suggestLocationAndRoom: false,
          }
    );
    setIsModalOpen(true);
  };

  return (
    <AppointmentAddModalContext.Provider
      value={{ isModalOpen, setIsModalOpen, setModalData }}
    >
      {children}
      {isAdmin && (
        <AppointmentAddModalAdminCustomizable
          isOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false);
            setOptions(null);
          }}
          options={options}
        />
      )}
      {!isAdmin && (
        <AppointmentAddModalCustomizable
          isOpen={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false);
            setOptions(null);
          }}
        />
      )}
    </AppointmentAddModalContext.Provider>
  );
};

// Custom hook to use the Appointment Add Modal context
const useAddAppointmentModal = () => {
  const context = useContext(AppointmentAddModalContext);
  if (!context) {
    throw new Error(
      "useAddAppointmentModal must be used within an AppointmentAddModalProvider"
    );
  }
  return context;
};

export { AppointmentAddModalProvider, useAddAppointmentModal };
